export default {
    nepal: {
        url: 'mapbox://adityakhatri.colcm1cq',
        layers: {
            // province: 'provincegeo',
            district: 'districtgeo',
            palika: 'municipalitygeo',
            ward: 'wardgeo',
        },
    },
};
